#mapid { height: 180px; }
.leaflet-container {
    height: 400px;
    width: 100%;
    margin: 0 auto;
  }
  .leaflet-container1 {
    height: 180px;
    width: 100%;
    margin: 0 auto;
  }
  .marker {
    display: block;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    }